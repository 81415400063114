import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Checkbox,
  Menu,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import TableSkeleton from "../../components/loader/tableLoader.jsx";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import moment from "moment";
import { NumberFormat } from "../../utils/numberFormat";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../components/responseAlert";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getPayments } from "../../redux/payments/getPayments";
import { deletePayment } from "../../redux/payments/deletePayment.js";
import ConfirmModal from "../../components/globalModal/ConfirmModal";
import { updatePaymentStatus } from "../../redux/payments/updatePaymentStatus.js";
import ApplyPayment from "../agentRoster/agentDetails/tabsContent/tabs/applyPayment.jsx";
function PaymentsTable(props) {
  const { classes, selectedPayments, setSelectedPayments } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.Payments.payments);
  const delPayment = useSelector((state) => state.Payments.delPayment);
  const updatePayment = useSelector((state) => state.Payments.updatePayment);

  const columns = [
    {
      field: "payment_date",
      headerName: "DATE",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.payment_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.status === "Pending"
                  ? "#dedede"
                  : row.status === "Posted"
                  ? "#499256"
                  : "#d8d8d8",
              color: row.status === "Posted" ? "white" : "",
            }}
            className={classes.statusChip}
          >
            {row.status}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "type",
      headerName: "TYPE",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.type?.replace("E-Payment App: ", "") === "Zelle" ||
                row.type?.replace("E-Payment App: ", "") === "Venmo" ||
                row.type?.replace("E-Payment App: ", "") === "Cash App"
                  ? "#ffe4ab"
                  : row.type === "Commission"
                  ? "black"
                  : row.type === "Check"
                  ? "#abd9ff"
                  : row.type === "Card Payment"
                  ? "#dbccff"
                  : "#d8d8d8",
              color: row.type === "Commission" ? "white" : "",
            }}
            className={classes.statusChip}
          >
            {row.type?.replace("E-Payment App: ", "")}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "agent",
      headerName: "Agent",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              color: "#696969",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>{row?.agent}</Typography>
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>{row?.description}</Typography>
        );
      },
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "amount",
      headerName: "Payment Amt",
      renderCell: ({ row }) => {
        return (
          <Box align="center">
            {row.amount
              ? NumberFormat({
                  number: Number(row?.amount),
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              : "$0.00"}
          </Box>
        );
      },
      alignItems: "center",
      width: 135,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            position: "relative",
            display: "fex",
          }}
        >
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedPayments((preSe) => [...preSe, row.id]);
              } else {
                setSelectedPayments((preSe) =>
                  [...preSe]?.filter((preId) => preId !== row.id)
                );
              }
            }}
            checked={selectedPayments?.includes(row.id)}
          />
          <Box sx={{ display: row?.status === "paid" ? "none" : "flex" }}>
            <IconButton
              onClick={(e) => {
                setOpenMenu(e.currentTarget);
                setPaymentDetail(row);
              }}
              size="small"
            >
              <MoreVertRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      ),
    },
  ];

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the payment",
      errorType: "success",
      isOpen: true,
    });
    setOpenDelete(false);
    setPaymentDetail({});
  };

  const handleConfirm = () => {
    dispatch(
      deletePayment({
        schema: {
          payment_id: paymentDetail.id,
          amount: paymentDetail?.amount,
        },
        handleError,
        handleSuccess,
      })
    );
  };

  const handleSuccessConfirm = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the payment status",
      errorType: "success",
      isOpen: true,
    });
    setOpenConfirm(false);
    setPaymentDetail({});
  };

  const handleRevertConfirm = () => {
    dispatch(
      updatePaymentStatus({
        schema: {
          status: "Pending",
          paymentIds: [paymentDetail.id],
        },
        handleError,
        handleSuccess: handleSuccessConfirm,
      })
    );
  };
  const handleSuccessCall = () => {
    dispatch(getPayments({}));
  };
  return (
    <Box className={classes.gridViewSection}>
      <Paper className={classes.tableRoot} sx={{ position: "relative" }}>
        <DataGridPro
          rows={payments?.data?.length ? payments?.data : []}
          loading={payments.isLoading}
          onRowClick={(data) => {}}
          onRowsScrollEnd={(e) => {
            if (payments?.count > e.virtualRowsCount && !payments.isLoading) {
              dispatch(
                getPayments({
                  pageNumber: Number(e.virtualRowsCount / 15) + 1,
                  pageSize: 15,
                })
              );
            }
          }}
          columns={columns}
          disableSelectionOnClick
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
        />
      </Paper>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
        {...props}
      />
      <Menu
        anchorEl={openMenu}
        id="power-search-menu"
        open={Boolean(openMenu)}
        onClose={() => {
          setOpenMenu(false);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            minWidth: "180px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List disablePadding>
          {paymentDetail?.status === "Posted" ? (
            <ListItem
              onClick={() => {
                setOpenConfirm(true);
                setOpenMenu(false);
              }}
              dense
              disablePadding
              size="small"
            >
              <ListItemButton>
                <ListItemText
                  className={classes.menuListText}
                  primary="Revert to Pending"
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem
              onClick={() => {
                setOpenEdit(true);
                setOpenMenu(false);
              }}
              dense
              disablePadding
              size="small"
            >
              <ListItemButton>
                <ListItemText
                  className={classes.menuListText}
                  primary="Edit Payment"
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            onClick={() => {
              setOpenDelete(true);
              setOpenMenu(false);
            }}
            dense
            disablePadding
            size="small"
          >
            <ListItemButton>
              <ListItemText
                className={classes.menuListText}
                primary="Delete Payment"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this payment?"
        handleConfirm={handleConfirm}
        loading={delPayment.isLoading}
      />
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        title="Revert Confirmation"
        content="Are you sure you want to Revert to Pending this payment?"
        handleConfirm={handleRevertConfirm}
        loading={updatePayment.isLoading}
      />
      <ApplyPayment
        open={openEdit}
        setOpen={setOpenEdit}
        prePaymentDetail={paymentDetail}
        isUpdate={true}
        handleSuccessCall={handleSuccessCall}
      />
    </Box>
  );
}

export default PaymentsTable;
