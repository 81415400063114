export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "30px 30px 9px 30px",
    marginBottom: "16px",
  },
  pageRootTraining: {
    padding: "10px",
    marginBottom: "16px",
  },
  pageRootInventory: {
    padding: "5px",
    marginBottom: "16px",
  },
  pageRootInventoryBottom: {
    padding: "20px",
    marginBottom: "16px",
  },
  pageRootPayment: {
    marginBottom: "16px",
    padding: "5px",
  },
  overViewTabCardMain: {
    marginBottom: "16px",
    padding: "10px 20px 15px 20px",
  },
  overViewTabCard: {
    padding: "15px",
    marginBottom: "20px",
  },
  overViewTabCardRightTop: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    height: "60px",
    fontFamily: "AvenirNext  !important",
  },
  overViewTabCardRightBottom: {
    fontSize: "40px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    textAlign: "right",
    fontFamily: "AvenirNext  !important",
  },
  overViewTabCardHeading: {
    fontSize: "24px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  overViewTabCardDes: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
  },
  overViewTabCardDesBottom: {
    paddingBottom: "0px  !important",
    "& .MuiTypography-root": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      textTransform: "capitalize !important",
      color: "rgba(0, 0, 0, 0.87)  !important",
    },
  },
  pageHeading: {
    fontSize: "26px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  agentInventoryCardDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentInventoryCardContentDes: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)  !important",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "-10px",
    marginBottom: "-10px",
  },
  agentInventoryCardHeader: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addAccountButton: {
    color: "white !important",
    background: "#0B0909  !important",
    fontSize: "12px  !important",
    textTransform: "capitalize !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  tableRoot: {
    height: "calc(100vh + 120px)",
    width: "100%",
    marginTop: "20px",
    backgroundColor: "white !important",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
  },
  paymentTableRoot: {
    maxHeight: "800px",
    height: "600px",
    width: "100%",
    marginTop: "20px",
    backgroundColor: "white !important",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
    position: "relative !important",
  },
  subTable: {
    height: "300px",
    width: "100%",
    marginTop: "5px",
    backgroundColor: "white !important",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
  },
  subTable01: {
    height: "auto",
    width: "100%",
    marginTop: "5px",
    backgroundColor: "white !important",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
  },
  deleteButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  historyLogsTime: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap !important",
    width: "155px !important",
    marginLeft: "15px",
  },

  historyLogsDetail: {
    fontSize: "14px !important",
    fontFamily: "AvenirNext-400  !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
  },
  importantNotesCard: {
    marginBottom: "20px",
    padding: "0px 15px",
    border: "1px solid rgba(33, 33, 33, 0.4)  !important",
  },
  importantNotesHeader: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    fontFamily: "AvenirNext  !important",
    maxWidth: "40ch !important",
    textOverflow: "ellipsis !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
  },
  importantNotesDes: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    marginTop: "10px  !important",
  },
  importantNotesContent: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    padding: "0px  !important",
  },
  noteDatesText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginLeft: "-7px !important",
  },
  trainingStageDropdown: {
    minWidth: "190px !important",
    borderRadius: "5px !important",
    backgroundColor: "#ECECEC !important",
    fontFamily: "AvenirNext  !important",
    outline: "none !important",
    border: "none !important",
    "& .MuiSelect-select": {
      fontFamily: "AvenirNext  !important",
      color: "black !important",
      fontSize: "14px !important",
      fontWeight: "500 !important",
      border: "none !important",
      outline: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      border: "none !important",
      outline: "none !important",
      borderRadius: "5px !important",
      "&.Mui-focused": {
        borderColor: "#ECECEC", // Change this to the color you want
      },
    },
  },
  enrollButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    marginRight: "10px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    borderRadius: "5px !important",
  },
  enrolledBadgeArea: {
    borderRadius: "7px !important",
    backgroundColor: "#F0F0F0 !important",
    minWidth: "145px !important",
    padding: "8px 15px !important",
  },
  enrolledBadgeHeading: {
    fontFamily: "AvenirNext  !important",
    color: "black !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textAlign: "center !important",
  },
  enrolledBadgeContent: {
    fontFamily: "AvenirNext  !important",
    color: "rgba(0, 0, 0, 0.50) !important",
    fontSize: "11px !important",
    fontWeight: "500 !important",
    textAlign: "center !important",
  },
  mentorSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 20px !important",
    marginBottom: "20px !important",
  },
  mentorSectionLabel: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "15px !important",
    fontWeight: "500 !important",
    minWidth: "60px !important",
  },
  mentorSectionBadge: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 10px !important",
    borderRadius: "7px !important",
    backgroundColor: "#F0F0F0 !important",
    padding: "8px !important",
    minWidth: "191px !important",
    cursor: "pointer !important",
  },
  mentorSectionBadgeContent: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
  },
  mentorSectionLabelPost: {
    color: "rgba(0, 0, 0, 0.40) !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
  },
  statCard: {
    width: "100% !important",
    borderRadius: "18px !important",
    padding: "16px !important",
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    height: "110px !important",
  },
  /* onboarding section */
  onboardingSection: {
    marginBottom: "16px",
    padding: "20px",
  },
  onboardingSetup: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  onboardingSetupHeading: {
    color: "black !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
  },
  onboardingSetupButton: {
    backgroundColor: "black !important",
    color: "white !important",
    fontSize: "13px !important",
    borderRadius: "30px !important",
    fontWeight: "400 !important",
    "&:disabled": {
      backgroundColor: "#BEBEBE !important", // Color for disabled state
      cursor: "not-allowed !important",
    },
  },
  viewDashboardButton: {
    background: "linear-gradient(0deg, #D102BC 0%, #7D00FA 100%)",
    color: "white !important",
    fontSize: "13px !important",
    borderRadius: "5px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
  },
  missingFieldsSection: {
    marginTop: "26px",
  },
  missingFieldsDesc: {
    color: "#f00 !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
    fontSize: "14px !important",
  },
  sentDate: {
    fontSize: "14px !important",
    textAlign: "right !important",
    color: "black !important",
    marginTop: "10px !important",
  },
  completedTaskHead: {
    fontSize: "18px !important",
    marginTop: "12px !important",
  },
  completedSetupButton: {
    height: "46px !important",
    borderRadius: "35px !important",
    backgroundColor: "rgba(4, 196, 0, 0.20) !important",
    color: "black !important",
    marginTop: "12px !important",
  },
  accessedSection: {
    marginTop: "16px !important",
    backgroundColor: "#D6D6D6 !important",
    borderRadius: "4px !important",
    padding: "8px 16px  !important",
  },
  accessedSectionDesc: {},
  setupCompletedSection: {
    display: "flex !important",
    flexDirection: "column !important",
    gap: "12px !important",
    marginTop: "12px !important",
  },
  setupCompletedSectionItem: {
    height: "46px !important",
    borderRadius: "22px !important",
    backgroundColor: "#F2F2F2 !important",
    padding: "6px 8px !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  setupCompletedSectionItemLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "12px !important",
    alignItems: "center !important",
  },
  itemLeftAvatar: {
    width: "34px !important",
    height: "34px !important",
    borderRadius: "50% !important",
    background: " linear-gradient(180deg, #979797 0%, #000 100%)",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    "& img": {
      width: "22px !important",
    },
  },
  setupCompletedSectionItemRight: {
    width: "34px !important",
    height: "34px !important",
    borderRadius: "50% !important",
    border: "1.5px solid #04C400 !important",
    background: "rgba(220, 255, 219, 0.50) !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  itemTitle: {},
  statCardTitle: {
    fontWeight: "300 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
  },
  topStatCard: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  statCardValue: {
    fontWeight: "400 !important",
    fontSize: "30px !important",
    lineHeight: "150% !important",
    color: "black !important",
    marginTop: "10px !important",
  },
  manageButtons: {
    display: "flex !important",
    justifyContent: "center !important",
    flexDirection: "column !important",
    alignItems: "center !important",
    gap: "16px !important",
    marginTop: "14px !important",
  },
  createInvoiceBTN: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    color: "#000 !important",
    width: "180px !important",
    background: "white !important",
    border: "1px solid #c9c9c9 !important",
    borderRadius: "20px !important",
  },
  gridViewSection: {
    width: "100% !important",
    borderRadius: "18px !important",
    padding: "0px 16px !important",
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    background: "white !important",
    marginBottom: "60px !important",
    "& .MuiDataGrid-columnHeadersInner": {
      background: "#EFEFEF !important",
      height: "40px !important",
      borderRadius: "30px !important",
      marginTop: "16px !important",
    },
    "& .MuiDataGrid-columnHeader": { height: "40px !important" },
    "& .MuiDataGrid-row": {
      marginBottom: "6px !important",
      marginTop: "6px !important",
      borderRadius: "20px !important",
      background: "white !important",
      boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
      position: "relative !important",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      color: "rgba(105, 105, 105, 0.87) !important",
      textTransform: "uppercase !important",
    },
  },
  PaymentViewSection: {
    width: "100% !important",
    borderRadius: "18px !important",
    background: "white !important",
    "& .MuiDataGrid-columnHeadersInner": {
      background: "#EFEFEF !important",
      height: "40px !important",
      borderRadius: "30px !important",
      marginTop: "16px !important",
    },
    "& .MuiDataGrid-columnHeader": { height: "40px !important" },
    "& .MuiDataGrid-row": {
      marginBottom: "6px !important",
      marginTop: "6px !important",
      borderRadius: "20px !important",
      background: "white !important",
      boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
      position: "relative !important",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      color: "rgba(105, 105, 105, 0.87) !important",
      textTransform: "uppercase !important",
    },
  },
  statusChip: {
    borderRadius: "5px !important",
    padding: "1px 8px !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  subHeading: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "uppercase !important",
    marginTop: "10px !important",
  },
  subHeading01: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    marginTop: "10px !important",
  },
  attachmentsView: {},
  attachmentsItem: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "12px !important",
    cursor: "pointer !important",
    textDecoration: "none !important",
  },
  attachments: {
    marginTop: "12px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-start !important",
    gap: "12px !important",
  },
  awardBudgetButton: {
    backgroundColor: "black !important",
    color: "white !important",
    borderRadius: "20px !important",
  },
  awardBudgetButtonSec: {
    height: "100% !important",
    width: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
});
