import { createSlice } from "@reduxjs/toolkit";
import { getInvoices } from "./getInvoices";
import { createInvoice } from "./createInvoice";
import { editInvoice } from "./updateInvoice";
import { deleteInvoice } from "./deleteInvoice";
import { getUnpaidInvoices } from "./getUnpaidInvoices";
import { createPayment } from "./applyPayment";
import { getPayments } from "./getPayments";
import { sendInvoiceAlert } from "./sendInvoiceAlert";
import { deletePayment } from "./deletePayment";
import { deleteAttachment } from "./deleteAttachment";
import { updatePayment } from "./updatePayment";
import { getMarketingBudget } from "./getMarketingBudget";
import { addAwardBudget } from "./awardBudget";
import { deleteAwardBudget } from "./deleteAward";
import { updateAwardBudget } from "./updateAwardBudget";
const initialState = {
  invoices: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
    marketingBudgetTotal: 0,
    unpaidAmount: 0,
    unpaidCount: 0,
  },
  unPaidInvoices: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  addInvoice: {
    success: false,
    isLoading: false,
    data: {},
  },
  updateInvoice: {
    success: false,
    isLoading: false,
    data: {},
  },
  delInvoice: {
    success: false,
    isLoading: false,
    data: {},
  },
  applyPayment: {
    success: false,
    isLoading: false,
    data: {},
  },
  payments: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    count: 0,
    paymentAmount: 0,
  },
  invoiceAlert: {
    success: false,
    isLoading: false,
    data: {},
  },
  delPayment: {
    success: false,
    isLoading: false,
    data: {},
  },
  delAttachment: {
    success: false,
    isLoading: false,
    data: {},
  },
  editPayment: {
    success: false,
    isLoading: false,
    data: {},
  },
  marketingBudget: {
    success: false,
    isLoading: false,
    data: {},
  },
  awardBudget: { success: false, isLoading: false, data: {} },
  delAward: {
    success: false,
    isLoading: false,
    data: {},
  },
  updateAward: {
    success: false,
    isLoading: false,
    data: {},
  },
};
const AgentTabs = createSlice({
  name: "AgentTabs",
  initialState,
  extraReducers: {
    /* get invoices */
    [getInvoices.pending]: (state) => {
      state.invoices.isLoading = true;
      state.invoices.errMsg = null;
      state.invoices.success = false;
    },
    [getInvoices.fulfilled]: (state, action) => {
      state.invoices.isLoading = false;
      state.invoices.success = true;
      if (action.payload?.pageNumber > 1) {
        state.invoices.data = state.invoices.data.concat(action.payload.data);
      } else {
        state.invoices.data = action.payload.data;
      }
      state.invoices.unpaidCount = action.payload?.unpaidCount;
      state.invoices.unpaidAmount = action.payload?.unpaidAmount;
      state.invoices.count = action.payload?.count;
      state.invoices.marketingBudgetTotal =
        action.payload?.marketingBudgetTotal;
    },
    [getInvoices.rejected]: (state, action) => {
      state.invoices.isLoading = false;
      state.invoices.success = false;
      state.invoices.errMsg = action.payload;
    },
    /* add invoice */
    [createInvoice.pending]: (state) => {
      state.addInvoice.isLoading = true;
      state.addInvoice.errMsg = null;
      state.addInvoice.success = false;
    },
    [createInvoice.fulfilled]: (state, action) => {
      state.addInvoice.isLoading = false;
      state.addInvoice.success = true;
      if (action.payload) {
        state.addInvoice.data = action.payload;
        state.invoices.data = [{ ...action.payload }, ...state.invoices.data];
        state.invoices.count = state.invoices.count + 1;
        state.invoices.unpaidCount = state.invoices.unpaidCount + 1;
      }
    },
    [createInvoice.rejected]: (state, action) => {
      state.addInvoice.isLoading = false;
      state.addInvoice.success = false;
      state.addInvoice.errMsg = action.payload;
    },
    /* update invoice */
    [editInvoice.pending]: (state) => {
      state.updateInvoice.isLoading = true;
      state.updateInvoice.errMsg = null;
      state.updateInvoice.success = false;
    },
    [editInvoice.fulfilled]: (state, action) => {
      state.updateInvoice.isLoading = false;
      state.updateInvoice.success = true;
      if (action.payload) {
        state.updateInvoice.data = action.payload;
        let preInvoices = [...state.invoices.data];
        let index = preInvoices.findIndex(
          (item) => item.id === action.payload.id
        );
        preInvoices[index] = { ...preInvoices[index], ...action.payload };
        state.invoices.data = [...preInvoices];
      }
    },
    [editInvoice.rejected]: (state, action) => {
      state.updateInvoice.isLoading = false;
      state.updateInvoice.success = false;
      state.updateInvoice.errMsg = action.payload;
    },
    /* delete invoice */
    [deleteInvoice.pending]: (state) => {
      state.delInvoice.isLoading = true;
      state.delInvoice.errMsg = null;
      state.delInvoice.success = false;
    },
    [deleteInvoice.fulfilled]: (state, action) => {
      state.delInvoice.isLoading = false;
      state.delInvoice.success = true;
      state.delInvoice.data = action.payload;
      let preInvoices = [...state.invoices.data];
      let index = preInvoices?.findIndex(
        (item) => item.id === action.payload.invoice_id
      );
      preInvoices?.splice(index, 1);
      state.invoices.data = [...preInvoices];
    },
    [deleteInvoice.rejected]: (state, action) => {
      state.delInvoice.isLoading = false;
      state.delInvoice.success = false;
      state.delInvoice.errMsg = action.payload;
    },
    /* get unpaid invoices */
    [getUnpaidInvoices.pending]: (state) => {
      state.unPaidInvoices.isLoading = true;
      state.unPaidInvoices.errMsg = null;
      state.unPaidInvoices.success = false;
    },
    [getUnpaidInvoices.fulfilled]: (state, action) => {
      state.unPaidInvoices.isLoading = false;
      state.unPaidInvoices.success = true;
      state.unPaidInvoices.data = action.payload;
    },
    [getUnpaidInvoices.rejected]: (state, action) => {
      state.unPaidInvoices.isLoading = false;
      state.unPaidInvoices.success = false;
      state.unPaidInvoices.errMsg = action.payload;
    },
    /* apply payment  */
    [createPayment.pending]: (state) => {
      state.applyPayment.isLoading = true;
      state.applyPayment.errMsg = null;
      state.applyPayment.success = false;
    },
    [createPayment.fulfilled]: (state, action) => {
      state.applyPayment.isLoading = false;
      state.applyPayment.success = true;
      state.applyPayment.data = action.payload;
    },
    [createPayment.rejected]: (state, action) => {
      state.applyPayment.isLoading = false;
      state.applyPayment.success = false;
      state.applyPayment.errMsg = action.payload;
    },
    /* get payments  */
    [getPayments.pending]: (state) => {
      state.payments.isLoading = true;
      state.payments.errMsg = null;
      state.payments.success = false;
    },
    [getPayments.fulfilled]: (state, action) => {
      state.payments.isLoading = false;
      state.payments.success = true;
      if (action.payload.pageNumber > 1) {
        state.payments.data = [...action.payload.data, ...state.payments.data];
      } else {
        state.payments.data = action.payload.data;
      }

      state.payments.paymentAmount = action.payload.paymentAmount;
      state.payments.count = action.payload.count;
    },
    [getPayments.rejected]: (state, action) => {
      state.payments.isLoading = false;
      state.payments.success = false;
      state.payments.errMsg = action.payload;
    },
    /* send invoice alert  */
    [sendInvoiceAlert.pending]: (state) => {
      state.invoiceAlert.isLoading = true;
      state.invoiceAlert.errMsg = null;
      state.invoiceAlert.success = false;
    },
    [sendInvoiceAlert.fulfilled]: (state, action) => {
      state.invoiceAlert.isLoading = false;
      state.invoiceAlert.success = true;
      state.invoiceAlert.data = action.payload.data;
    },
    [sendInvoiceAlert.rejected]: (state, action) => {
      state.invoiceAlert.isLoading = false;
      state.invoiceAlert.success = false;
      state.invoiceAlert.errMsg = action.payload;
    },
    /* delete payment  */
    [deletePayment.pending]: (state) => {
      state.delPayment.isLoading = true;
      state.delPayment.errMsg = null;
      state.delPayment.success = false;
    },
    [deletePayment.fulfilled]: (state, action) => {
      state.delPayment.isLoading = false;
      state.delPayment.success = true;
      state.delPayment.data = action.payload.data;
      let prePayments = [...state.payments.data];
      let index = prePayments?.findIndex(
        (item) => item.id === action.payload.payment_id
      );
      prePayments?.splice(index, 1);
      state.payments.data = [...prePayments];
      if (action.payload.amount) {
        state.payments.paymentAmount =
          Number(state.payments.paymentAmount || 0) -
          Number(action.payload.amount || 0);
      }
    },
    [deletePayment.rejected]: (state, action) => {
      state.delPayment.isLoading = false;
      state.delPayment.success = false;
      state.delPayment.errMsg = action.payload;
    },
    /* delete attachment  */
    [deleteAttachment.pending]: (state) => {
      state.delAttachment.isLoading = true;
      state.delAttachment.errMsg = null;
      state.delAttachment.success = false;
    },
    [deleteAttachment.fulfilled]: (state, action) => {
      state.delAttachment.isLoading = false;
      state.delAttachment.success = true;
      state.delAttachment.data = action.payload.data;
    },
    [deleteAttachment.rejected]: (state, action) => {
      state.delAttachment.isLoading = false;
      state.delAttachment.success = false;
      state.delAttachment.errMsg = action.payload;
    },
    /* update payment */
    [updatePayment.pending]: (state) => {
      state.editPayment.isLoading = true;
      state.editPayment.errMsg = null;
      state.editPayment.success = false;
    },
    [updatePayment.fulfilled]: (state, action) => {
      state.editPayment.isLoading = false;
      state.editPayment.success = true;
      state.editPayment.data = action.payload.data;
      let prePayments = [...state.payments.data];
      let index = prePayments?.findIndex(
        (item) => item.id === action.payload.id
      );
      prePayments[index] = {
        ...prePayments[index],
        ...action.payload,
        payment_invoices: [...action.payload?.payment_invoices],
        payment_attachments: [...action.payload?.payment_attachments],
      };
      state.payments.data = prePayments;
    },
    [updatePayment.rejected]: (state, action) => {
      state.editPayment.isLoading = false;
      state.editPayment.success = false;
      state.editPayment.errMsg = action.payload;
    },

    /* get marketing budget */
    [getMarketingBudget.pending]: (state) => {
      state.marketingBudget.isLoading = true;
      state.marketingBudget.errMsg = null;
      state.marketingBudget.success = false;
    },
    [getMarketingBudget.fulfilled]: (state, action) => {
      state.marketingBudget.isLoading = false;
      state.marketingBudget.success = true;
      if (action.payload.pageNumber > 1) {
        state.marketingBudget.data = [
          ...action.payload.data,
          ...state.marketingBudget.data,
        ];
      } else {
        state.marketingBudget.data = action.payload.data;
      }
    },
    [getMarketingBudget.rejected]: (state, action) => {
      state.marketingBudget.isLoading = false;
      state.marketingBudget.success = false;
      state.marketingBudget.errMsg = action.payload;
    },
    /* get award budget */
    [addAwardBudget.pending]: (state) => {
      state.awardBudget.isLoading = true;
      state.awardBudget.errMsg = null;
      state.awardBudget.success = false;
    },
    [addAwardBudget.fulfilled]: (state, action) => {
      state.awardBudget.isLoading = false;
      state.awardBudget.success = true;
      state.awardBudget.data = action.payload;
      let preBudgets = [...state.marketingBudget.data];
      preBudgets.push(action.payload);
      state.marketingBudget.data = preBudgets;
      state.invoices.marketingBudgetTotal =
        Number(state.invoices.marketingBudgetTotal || 0) +
        Number(action.payload.amount || 0);
    },
    [addAwardBudget.rejected]: (state, action) => {
      state.awardBudget.isLoading = false;
      state.awardBudget.success = false;
      state.awardBudget.errMsg = action.payload;
    },
    /* delete award budget */
    [deleteAwardBudget.pending]: (state) => {
      state.delAward.isLoading = true;
      state.delAward.errMsg = null;
      state.delAward.success = false;
    },
    [deleteAwardBudget.fulfilled]: (state, action) => {
      state.delAward.isLoading = false;
      state.delAward.success = true;
      state.delAward.data = action.payload;
      let preBudgets = [...state.marketingBudget.data];
      let index = preBudgets.findIndex(
        (item) => item.id === action.payload.marketing_budget_id
      );
      preBudgets.splice(index, 1);
      state.marketingBudget.data = preBudgets;
      state.invoices.marketingBudgetTotal =
        Number(state.invoices.marketingBudgetTotal || 0) -
        Number(action.payload.amount || 0);
    },
    [deleteAwardBudget.rejected]: (state, action) => {
      state.delAward.isLoading = false;
      state.delAward.success = false;
      state.delAward.errMsg = action.payload;
    },
    /* update award budget */
    [updateAwardBudget.pending]: (state) => {
      state.updateAward.isLoading = true;
      state.updateAward.errMsg = null;
      state.updateAward.success = false;
    },
    [updateAwardBudget.fulfilled]: (state, action) => {
      state.updateAward.isLoading = false;
      state.updateAward.success = true;
      state.updateAward.data = action.payload;
      let preBudgets = [...state.marketingBudget.data];
      let index = preBudgets.findIndex(
        (item) => item.id === action.payload.marketing_budget_id
      );
      let preAmount = preBudgets[index].amount;
      preBudgets[index] = { ...preBudgets[index], ...action.payload };
      state.marketingBudget.data = preBudgets;

      state.invoices.marketingBudgetTotal =
        Number(state.invoices.marketingBudgetTotal || 0) -
        Number(preAmount) +
        Number(action.payload.amount || 0);
    },
    [updateAwardBudget.rejected]: (state, action) => {
      state.updateAward.isLoading = false;
      state.updateAward.success = false;
      state.updateAward.errMsg = action.payload;
    },
  },
});
export default AgentTabs.reducer;
export * from "./getInvoices";
export * from "./createInvoice";
export * from "./updateInvoice";
export * from "./deleteInvoice";
export * from "./getUnpaidInvoices";
export * from "./applyPayment";
export * from "./getPayments";
export * from "./sendInvoiceAlert";
export * from "./deletePayment";
export * from "./deleteAttachment";
export * from "./updatePayment";
export * from "./awardBudget";
export * from "./getMarketingBudget";
export * from "./deleteAward";
export * from "./updateAwardBudget";
