import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Typography,
  Skeleton,
  Box,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getInvoices,
  getPayments,
  getMarketingBudget,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import ButtonGroups from "../../../../../components/uiElements/buttonGroup";
import GenerateInvoice from "./generateInvoice.jsx";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import ApplyPayment from "./applyPayment.jsx";
import CreateInvoice from "./createInvoice.jsx";
import InvoicesTable from "./invoicesTable.jsx";
import { NumberFormat } from "../../../../../utils/numberFormat";
import PaymentsView from "./payments.jsx";
import MarketingBudget from "./marketingBudget.jsx";
const AgentRosterTabs = (props) => {
  const { id } = useParams();
  const { classes } = props;
  const dispatch = useDispatch();
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [openCreateInvoice, setOpenCreateInvoice] = useState(false);
  const [openPayments, setOpenPayments] = useState(false);
  const [openApplyPayment, setOpenApplyPayment] = useState(false);
  const [openAwardBudget, setOpenAwardBudget] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    search: "",
  });
  const invoices = useSelector((state) => state.agentRoster.AgentTabs.invoices);

  useEffect(() => {
    dispatch(getInvoices({ user_id: id, ...filters }));
  }, [filters]);

  useEffect(() => {
    dispatch(getPayments({ user_id: id }));
    dispatch(getMarketingBudget({ user_id: id }));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mb: "16px" }}>
        <Grid item xs={12} md={3}>
          <Card className={classes.statCard}>
            <Box className={classes.topStatCard}>
              <Typography className={classes.statCardTitle}>
                Unpaid Total
              </Typography>
              <IconButton sx={{ mt: "-5px", mr: "-5px" }} size="small">
                {" "}
                <FilterAltRoundedIcon sx={{ color: "#c2c2c2" }} />
              </IconButton>
            </Box>
            <Typography className={classes.statCardValue}>
              {invoices.isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1.5rem",
                    display: "inline-block",
                    width: "150px",
                  }}
                />
              ) : invoices?.unpaidAmount ? (
                NumberFormat({
                  number: invoices?.unpaidAmount,
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              ) : (
                "0"
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            onClick={() => setOpenAwardBudget(true)}
            className={classes.statCard}
            sx={{ cursor: "pointer" }}
          >
            <Box className={classes.topStatCard}>
              <Typography className={classes.statCardTitle}>
                Marketing Budget
              </Typography>
              <IconButton sx={{ mt: "-5px", mr: "-5px" }} size="small">
                {" "}
                <CropFreeRoundedIcon sx={{ color: "#c2c2c2" }} />
              </IconButton>
            </Box>
            <Typography className={classes.statCardValue}>
              {" "}
              {invoices.isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1.5rem",
                    display: "inline-block",
                    width: "150px",
                  }}
                />
              ) : invoices?.marketingBudgetTotal ? (
                NumberFormat({
                  number: invoices?.marketingBudgetTotal,
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              ) : (
                "0"
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card className={classes.statCard}>
            <Box className={classes.topStatCard}>
              <Typography className={classes.statCardTitle}>
                Unpaid Invoices
              </Typography>
            </Box>
            <Typography className={classes.statCardValue}>
              {invoices.isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1.5rem",
                    display: "inline-block",
                    width: "150px",
                  }}
                />
              ) : invoices?.unpaidCount ? (
                invoices?.unpaidCount
              ) : (
                "0"
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.manageButtons}>
            <Button
              color="inherit"
              variant="outlined"
              className={classes.createInvoiceBTN}
              onClick={() => setOpenCreateInvoice(true)}
            >
              Create Invoice
            </Button>
            <ButtonGroups
              id="Manage-button-menu-inventory"
              bgColor="#0B0909"
              bgColorHover="#0B0909"
              placement={"bottom"}
              height="36px"
              size="medium"
              color="white"
              minWidth="140px"
              options={[
                {
                  title: "Apply Payment",
                  value: "apply_payment",
                },
                {
                  title: "Generate Statement",
                  value: "generate_statement",
                },
                {
                  title: "View Payments",
                  value: "view_payments",
                },
              ]}
              onChangeMenu={(value) => {
                if (value === "apply_payment") {
                  setOpenApplyPayment(true);
                } else if (value === "view_payments") {
                  setOpenPayments(true);
                } else if (value === "generate_statement") {
                  setOpenGenerateModal(true);
                }
              }}
              fontSize={"12px"}
              itemFontSize="12px"
              placeholder={"Manage"}
              borderRadius="20px"
              justifyContent="center"
              buttonTextAlign
            />
          </Box>
        </Grid>
      </Grid>
      <InvoicesTable
        filters={filters}
        setFilters={setFilters}
        id={id}
        {...props}
      />

      <GenerateInvoice
        open={openGenerateModal}
        setOpen={setOpenGenerateModal}
        id={id}
      />
      <CreateInvoice
        open={openCreateInvoice}
        setOpen={setOpenCreateInvoice}
        id={id}
      />
      <ApplyPayment
        open={openApplyPayment}
        setOpen={setOpenApplyPayment}
        id={id}
      />
      <PaymentsView
        {...props}
        open={openPayments}
        setOpen={setOpenPayments}
        setOpenApplyPayment={setOpenApplyPayment}
        id={id}
      />
      <MarketingBudget
        open={openAwardBudget}
        setOpen={setOpenAwardBudget}
        id={id}
        {...props}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "AgentRosterTabsStyle",
})(AgentRosterTabs);
