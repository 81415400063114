export const styleSheet = (theme) => ({
  modalHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "20px",
    background: "#ECECEC !important",
    padding: "12px 16px !important",
  },
  modalHeading: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    textTransform: "uppercase !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    paddingLeft: "6px !important",
  },
  invoiceForm: {
    padding: "40px !important",
  },
  InputLabel: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    marginBottom: "6px !important",
  },
  InputLabel2: {
    color: "black !important",
    fontSize: "17px !important",
    fontWeight: "400 !important",
    marginBottom: "6px !important",
  },
  buttonSection: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "12px !important",
    width: "100% !important",
    padding: "16px !important",
  },
  saveButton: {
    borderRadius: "16px !important",
    fontSize: "12px !important",
    color: "black !important",
    border: "1px solid #C9C9C9 !important",
  },
  createButton: {
    borderRadius: "16px !important",
    fontSize: "12px !important",
    background: "black !important",
    color: "white !important",
  },
  selectionBox: {
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    borderRadius: "18px !important",
    background: "white !important",
    padding: "8px 24px !important",
  },
  chargesSection: { marginTop: "20px" },
  chargesSectionItem: {
    borderRadius: "18px !important",
    overflow: "hidden !important",
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    marginBottom: "12px !important",
  },
  chargesSectionItemHeading: {
    background: "#ECECEC !important",
    padding: "6px 16px !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  unpaidInvoices: {
    marginTop: "20px !important",
  },
  invoiceTable: {
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    background: "white !important",
    borderRadius: "18px !important",
    padding: "12px 20px !important",
  },
  tableRoot: {
    maxHeight: "500px",
    height: "300px",
    width: "100%",
    backgroundColor: "white !important",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
    "& .MuiDataGrid-columnHeadersInner": {
      background: "#EFEFEF !important",
      height: "40px !important",
      borderRadius: "30px !important",
      marginTop: "16px !important",
    },
    "& .MuiDataGrid-columnHeader": { height: "40px !important" },
    "& .MuiDataGrid-row": {
      marginBottom: "6px !important",
      marginTop: "6px !important",
      borderRadius: "20px !important",
      background: "white !important",
      boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
      position: "relative !important",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      color: "rgba(105, 105, 105, 0.87) !important",
      textTransform: "uppercase !important",
    },
  },
  attachmentSection: {
    marginTop: "20px",
  },
  attachments: {
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    background: "white !important",
    borderRadius: "18px !important",
    padding: "12px!important",
  },
  dropzoneSection: {
    border: "2px dashed rgba(0, 0, 0, 0.40)",
    borderRadius: "18px !important",
    background: "#FBFBFB !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    height: "150px !important",
    color: "rgba(0, 0, 0, 0.50) !important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600",
    cursor: "pointer !important",
  },
  downloadButton: {
    border: "1px solid #0B0909 !important",
    color: "rgba(0, 0, 0, 0.6) !important",
    minWidth: "40px !important",
  },
});
