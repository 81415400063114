import React, { useState, Fragment, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  InputLabel,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import ResponseAlert from "../../../../../components/responseAlert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  addAwardBudget,
  updateAwardBudget,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import { useDispatch, useSelector } from "react-redux";
function AwardBudget(props) {
  const { open, classes, setOpen, id, isUpdate, preAwardDetail } = props;
  const [awardDetail, setAwardDetail] = useState({
    marketing_budget_date: "",
    amount: "",
    description: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const awardBudget = useSelector(
    (state) => state.agentRoster.AgentTabs.awardBudget
  );
  const updateAward = useSelector(
    (state) => state.agentRoster.AgentTabs.updateAward
  );
  const handleClose = () => {
    setOpen(false);
    setAwardDetail({
      marketing_budget_date: "",
      amount: "",
      description: "",
    });
  };
  const handleUpdateDetail = (value, field) => {
    setAwardDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors({ ...errors, [field]: "" });
    }
  };
  const validateFields = (fields) => {
    const errors = {};
    let isFormValid = true;

    fields.forEach(({ key, message }) => {
      if (!awardDetail?.[key]) {
        errors[key] = message;
        isFormValid = false;
      }
    });
    return { errors, isFormValid };
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully added the award budget",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleSuccessUpdate = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the award budget",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleValidate = () => {
    const validationRules = [
      {
        key: "marketing_budget_date",
        message: "Please select the award budget date",
      },
      { key: "amount", message: "Please enter the amount of budget" },
      { key: "description", message: "Please enter the budget description" },
    ];

    const { errors, isFormValid } = validateFields(validationRules);
    setErrors(errors);
    return isFormValid;
  };
  const handleAwardBudget = () => {
    if (handleValidate()) {
      dispatch(
        addAwardBudget({
          schema: { ...awardDetail, user_id: id },
          handleError,
          handleSuccess,
        })
      );
    }
  };

  const handleUpdateAwardBudget = () => {
    if (handleValidate()) {
      dispatch(
        updateAwardBudget({
          marketing_budget_id: preAwardDetail?.id,
          schema: { ...awardDetail },
          handleError,
          handleSuccess: handleSuccessUpdate,
        })
      );
    }
  };

  useEffect(() => {
    if ((preAwardDetail.id, open)) {
      setAwardDetail({
        marketing_budget_date: preAwardDetail?.marketing_budget_date,
        amount: preAwardDetail?.amount,
        description: preAwardDetail?.description,
      });
    }
  }, [preAwardDetail?.id, open]);

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {isUpdate ? "Update Award Budget" : "Award Budget"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel className={classes.InputLabel}>Date</InputLabel>
                <DatePicker
                  componentsProps={{
                    actionBar: {
                      actions: ["clear", "cancel", "accept"],
                    },
                  }}
                  value={awardDetail.marketing_budget_date}
                  onChange={(newValue) =>
                    handleUpdateDetail(
                      new Date(newValue),
                      "marketing_budget_date"
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.marketing_budget_date}
                      helperText={errors.marketing_budget_date}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={classes.InputLabel}>Amount</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={awardDetail.amount}
                  onChange={(e) => handleUpdateDetail(e.target.value, "amount")}
                  error={errors.amount}
                  helperText={errors.amount}
                  type={"number"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.InputLabel}>
                  Award Description
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={awardDetail.description}
                  onChange={(e) =>
                    handleUpdateDetail(e.target.value, "description")
                  }
                  error={errors.description}
                  helperText={errors.description}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <br />
          <Box className={classes.buttonSection}>
            {isUpdate ? (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                loadingPosition="start"
                loading={updateAward.isLoading}
                disabled={updateAward.isLoading}
                onClick={handleUpdateAwardBudget}
              >
                Update Award budget
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                loadingPosition="start"
                loading={awardBudget.isLoading}
                disabled={awardBudget.isLoading}
                onClick={handleAwardBudget}
              >
                Award budget
              </LoadingButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}
export default withStyles(styleSheet, { name: "AwardBudgetStyle" })(
  AwardBudget
);
