import React, { useState, Fragment } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import ResponseAlert from "../../../../../components/responseAlert";
import moment from "moment";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function GenerateInvoice(props) {
  const { open, classes, setOpen, id } = props;
  const [filters, setFilters] = useState({
    start_date: moment().format(),
    end_date: moment().add(30, "days").format(),
  });
  const [pdfData, setPdfData] = React.useState(null);
  const [pdfDataLoading, setPdfDataLoading] = React.useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setOpen(false);
  };

  const generateStatement = async (userId, { end_date, start_date }) => {
    try {
      setPdfDataLoading(true);

      let queryParams = "";

      if (end_date && start_date) {
        queryParams = `?start_date=${moment
          .parseZone(start_date)
          .format("YYYY-MM-DD")}T00:00:01&end_date=${moment
          .parseZone(end_date)
          .format("YYYY-MM-DD")}T23:59:59`;
      }
      const url = `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/generate-statement/${userId}${queryParams}`;
      const resp = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.token,
          Accept: "application/pdf",
        },
      });

      const contentDispositionHeader = resp.headers["content-disposition"];
      let filename = "test";
      if (contentDispositionHeader)
        filename = contentDispositionHeader.split("filename=")[1];

      const pdfUrl = URL.createObjectURL(resp.data, filename);

      return { url: pdfUrl, filename: filename };
    } catch (error) {
      console.log(error);
      // handle error
    }
  };

  const handleGenerateStatement = () => {
    generateStatement(id, filters)
      .then((data) => {
        setPdfDataLoading(false);
        const { url, filename } = data;

        setPdfData({
          url: url,
          filename: filename,
        });
      })
      .catch((error) => {
        console.log(error);
        // handle error
      });
  };

  const downloadPdf = () => {
    try {
      const link = document.createElement("a");
      link.href = pdfData.url;
      link.download = pdfData.filename;
      document.body.append(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth={pdfData?.url ? "md" : "sm"}
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            Generate Statement
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ maxWidth: "500px" }}>
              <Grid container spacing={2} display="flex" alignItems="center">
                <Grid item xs={6}>
                  <DatePicker
                    label="Start Date"
                    componentsProps={{
                      actionBar: {
                        actions: ["clear", "cancel", "accept"],
                      },
                    }}
                    value={filters.start_date}
                    onChange={(newValue) =>
                      setFilters({ ...filters, start_date: new Date(newValue) })
                    }
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="End Date"
                    componentsProps={{
                      actionBar: {
                        actions: ["clear", "cancel", "accept"],
                      },
                    }}
                    value={filters.end_date}
                    onChange={(newValue) =>
                      setFilters({ ...filters, end_date: new Date(newValue) })
                    }
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>

          <br />
          <Box className={classes.buttonSection}>
            {pdfData?.url && (
              <Button className={classes.downloadButton} onClick={downloadPdf}>
                <FileDownloadIcon />
              </Button>
            )}
            <LoadingButton
              variant="contained"
              color="inherit"
              className={classes.createButton}
              loadingPosition="start"
              loading={pdfDataLoading}
              onClick={handleGenerateStatement}
              disabled={pdfDataLoading}
            >
              Generate
            </LoadingButton>
          </Box>

          {pdfData?.url && (
            <Box sx={{ marginTop: "0.5rem" }}>
              <iframe src={pdfData.url} width={"100%"} height={550}></iframe>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}
export default withStyles(styleSheet, { name: "GenerateInvoiceStyle" })(
  GenerateInvoice
);
