import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import {
  Box,
  Container,
  Grid,
  Card,
  Typography,
  Skeleton,
  IconButton,
  Button,
} from "@mui/material";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import PaymentsTable from "./paymentTable";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormat } from "../../utils/numberFormat";
import PostPayment from "./modal/postPayment";
import { getPayments } from "../../redux/payments/getPayments";
function Payments(props) {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.Payments.payments);

  useEffect(() => {
    dispatch(getPayments({}));
  }, []);

  return (
    <Box className={classes.pageRoot}>
      <Container>
        {" "}
        <Grid container spacing={2} sx={{ mb: "16px" }}>
          <Grid item xs={12} md={3}>
            <Card className={classes.statCard}>
              <Box className={classes.topStatCard}>
                <Typography className={classes.statCardTitle}>
                  Pending Payments
                </Typography>
                <IconButton sx={{ mt: "-5px", mr: "-5px" }} size="small">
                  {" "}
                  <FilterAltRoundedIcon sx={{ color: "#c2c2c2" }} />
                </IconButton>
              </Box>
              <Typography className={classes.statCardValue}>
                {" "}
                {payments.isLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.5rem",
                      display: "inline-block",
                      width: "150px",
                    }}
                  />
                ) : payments?.pendingPaymentAmount ? (
                  NumberFormat({
                    number: payments?.pendingPaymentAmount,
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })
                ) : (
                  "0"
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.statCard}>
              <Box className={classes.topStatCard}>
                <Typography className={classes.statCardTitle}>
                  Pending Amount
                </Typography>
                <IconButton sx={{ mt: "-5px", mr: "-5px" }} size="small">
                  {" "}
                  <CropFreeRoundedIcon sx={{ color: "#c2c2c2" }} />
                </IconButton>
              </Box>
              <Typography className={classes.statCardValue}>
                {" "}
                {payments.isLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.5rem",
                      display: "inline-block",
                      width: "150px",
                    }}
                  />
                ) : payments?.pendingPaymentAmount ? (
                  NumberFormat({
                    number: payments?.pendingPaymentAmount,
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })
                ) : (
                  "0"
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.statCard}>
              <Box className={classes.topStatCard}>
                <Typography className={classes.statCardTitle}>
                  Posted Total (YTD)
                </Typography>
              </Box>
              <Typography className={classes.statCardValue}>
                {payments.isLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.5rem",
                      display: "inline-block",
                      width: "150px",
                    }}
                  />
                ) : payments?.totalPaymentAmount ? (
                  NumberFormat({
                    number: payments?.totalPaymentAmount,
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })
                ) : (
                  "0"
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className={classes.manageButtons}>
              <Button
                color="inherit"
                variant="contained"
                className={classes.postPaymentButton}
                onClick={() => setOpen(true)}
              >
                Post Payment
              </Button>
            </Box>
          </Grid>
        </Grid>
        <PaymentsTable
          {...props}
          setSelectedPayments={setSelectedPayments}
          selectedPayments={selectedPayments}
        />
      </Container>
      <PostPayment
        open={open}
        setOpen={setOpen}
        setSelectedPayments={setSelectedPayments}
        selectedPayments={selectedPayments}
        {...props}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "PaymentsStyle" })(Payments);
