import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnpaidInvoices,
  createPayment,
  deleteAttachment,
  updatePayment,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import moment from "moment";
import { NumberFormat } from "../../../../../utils/numberFormat";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ResponseAlert from "../../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";
const columns = [
  {
    field: "invoice_date",
    headerName: "DATE",
    width: 120,
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: "14px" }}>
          {moment(row?.invoice_date).format("L")}
        </Typography>
      );
    },
  },
  { field: "invoice_number", headerName: "INVOICE #", width: 130 },
  { field: "category", headerName: "CATEGORY", minWidth: 120, flex: 1 },
  {
    field: "amount_due",
    headerName: "AMT DUE",
    width: 90,
    renderCell: ({ row }) => {
      return (
        <Box align="center">
          {row.amount_due
            ? NumberFormat({
                number: Number(row?.amount_due),
                maximumFractionDigits: 2,
                currency: "USD",
              })
            : "$0.00"}
        </Box>
      );
    },
  },
  {
    field: "amount",
    headerName: "Payment",
    sortable: false,
    width: 100,
    renderCell: ({ row }) => {
      return (
        <Box align="center">
          {row.amount
            ? NumberFormat({
                number: Number(row?.amount),
                maximumFractionDigits: 2,
                currency: "USD",
              })
            : "$0.00"}
        </Box>
      );
    },
  },
];

function ApplyPayment(props) {
  const {
    classes,
    open,
    setOpen,
    id,
    isUpdate,
    prePaymentDetail,
    handleSuccessCall,
  } = props;
  const dispatch = useDispatch();
  const [paymentDetail, setPaymentDetail] = useState({
    payment_date: "",
    amount: "",
    type: "",
    description: "",
    email_alert: false,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [fileDetail, setFileDetail] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [errors, setErrors] = useState({});
  const [filesPreview, setFilesPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const unPaidInvoices = useSelector(
    (state) => state.agentRoster.AgentTabs.unPaidInvoices
  );
  const applyPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.applyPayment
  );
  const editPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.editPayment
  );
  const delAttachment = useSelector(
    (state) => state.agentRoster.AgentTabs.delAttachment
  );

  const handleClose = () => {
    setOpen(false);
    setPaymentDetail({
      payment_date: "",
      amount: "",
      type: "",
      description: "",
      email_alert: false,
    });
    setSelectedInvoices([]);
    setFilesPreview([]);
    setFiles([]);
  };
  const handleUpdateDetail = (value, field) => {
    setPaymentDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors({ ...errors, [field]: "" });
    }
  };

  const handleSelectionChange = (selectionModel) => {
    const selected = unPaidInvoices?.data.filter((row) =>
      selectionModel.includes(row.id)
    );
    setSelectedInvoices(selected);
  };

  const handleFileChange = (files) => {
    let id = Math.random().toString(36).substring(7);
    setFiles((preFiles) => [...preFiles, { id: id, file: files[0] }]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilesPreview([
        ...filesPreview,
        {
          id: id,
          file: reader.result,
          url: URL.createObjectURL(files[0]),
        },
      ]);
    };
    let url = reader.readAsDataURL(files[0]);
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };
  const handleSuccessAttachment = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the attachment",
      errorType: "success",
      isOpen: true,
    });
    setFileDetail({});
    setOpenDelete(false);
    setFilesPreview(filesPreview.filter((ite) => ite.id != fileDetail.id));
  };

  const handleDelFile = (item) => {
    if (item?.file_path) {
      setFileDetail(item);
      setOpenDelete(true);
    } else {
      setFiles(files.filter((ite) => ite.id != item.id));
      setFilesPreview(filesPreview.filter((ite) => ite.id != item.id));
    }
  };

  const handleConfirm = () => {
    dispatch(
      deleteAttachment({
        attachment_id: fileDetail.id,
        payment_id: prePaymentDetail.id,
        handleError,
        handleSuccess: handleSuccessAttachment,
      })
    );
  };

  const validateFields = (fields) => {
    const errors = {};
    let isFormValid = true;

    fields.forEach(({ key, message }) => {
      if (!paymentDetail?.[key]) {
        errors[key] = message;
        isFormValid = false;
      }
    });
    return { errors, isFormValid };
  };

  const handleValidate = () => {
    const validationRules = [
      { key: "payment_date", message: "Please enter the payment date" },
      { key: "amount", message: "Please enter the amount" },
      { key: "type", message: "Please select the type of payment" },
      { key: "description", message: "Please enter the invoice description" },
    ];

    const { errors, isFormValid } = validateFields(validationRules);
    setErrors(errors);
    return isFormValid;
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully applied the payment",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleSuccessUpdate = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the payment",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
    if (handleSuccessCall) {
      handleSuccessCall();
    }
  };
  const handleSubmitFormData = () => {
    const Data = new FormData();
    Data.append("user_id", id);
    Data.append("payment_date", paymentDetail?.payment_date);
    Data.append("amount", paymentDetail?.amount);
    Data.append("type", paymentDetail?.type);
    Data.append("description", paymentDetail?.description);
    selectedInvoices?.map((item, index) => {
      Data.append(`invoices[${index}][invoice_id]`, item?.id);
      Data.append(`invoices[${index}][amount_due]`, item?.amount_due || 0);
      Data.append(`invoices[${index}][amount_paid]`, item?.amount_due || 0);
      Data.append(`invoices[${index}][is_new]`, true);
    });
    files.map((item) => {
      Data.append("attachments[]", item.file);
    });

    dispatch(
      createPayment({
        formData: Data,
        handleSuccess,
        handleError,
      })
    );
  };
  const handleUpdateFormData = () => {
    const Data = new FormData();
    Data.append("payment_date", paymentDetail?.payment_date);
    Data.append("amount", paymentDetail?.amount);
    Data.append("type", paymentDetail?.type);
    Data.append("description", paymentDetail?.description);
    selectedInvoices?.map((item, index) => {
      Data.append(`invoices[${index}][invoice_id]`, item?.id);
      Data.append(
        `invoices[${index}][amount_due]`,
        item?.amount_due || item.amount_paid || 0
      );
      Data.append(
        `invoices[${index}][amount_paid]`,
        item?.amount_due || item.amount_paid || 0
      );
      if (!item.is_new) {
      } else {
        Data.append(`invoices[${index}][is_new]`, true);
      }
    });
    files.map((item) => {
      Data.append("attachments[]", item.file);
    });
    dispatch(
      updatePayment({
        payment_id: prePaymentDetail?.id,
        formData: Data,
        handleSuccess: handleSuccessUpdate,
        handleError,
      })
    );
  };

  const handleApplyPayment = () => {
    if (handleValidate()) {
      if (selectedInvoices?.length) {
        if (files?.length) {
          handleSubmitFormData();
        } else {
          setErrorAlert({
            errorMsg: "Please upload the invoices attachment first",
            errorType: "warning",
            isOpen: true,
          });
        }
      } else {
        setErrorAlert({
          errorMsg: "Please select unpaid invoice first",
          errorType: "warning",
          isOpen: true,
        });
      }
    }
  };
  const handleUpdatePayment = () => {
    if (handleValidate()) {
      if (selectedInvoices?.length) {
        if (files?.length || filesPreview?.length) {
          handleUpdateFormData();
        } else {
          setErrorAlert({
            errorMsg: "Please upload the invoices attachment first",
            errorType: "warning",
            isOpen: true,
          });
        }
      } else {
        setErrorAlert({
          errorMsg: "Please select unpaid invoice first",
          errorType: "warning",
          isOpen: true,
        });
      }
    }
  };

  useEffect(() => {
    if (!unPaidInvoices?.data?.length && id) {
      dispatch(getUnpaidInvoices({ user_id: id }));
    }
  }, [unPaidInvoices?.data?.length, open, id]);

  useEffect(() => {
    if (prePaymentDetail?.id && open) {
      setPaymentDetail({
        payment_date: moment(prePaymentDetail?.payment_date).format(
          "YYYY-MM-DD"
        ),
        amount: prePaymentDetail?.amount,
        type: prePaymentDetail?.type,
        description: prePaymentDetail?.description,
        email_alert: prePaymentDetail?.email_alert || false,
      });
      if (prePaymentDetail?.payment_attachments?.length) {
        setFilesPreview(prePaymentDetail?.payment_attachments);
      }
      if (prePaymentDetail?.payment_invoices?.length) {
        setSelectedInvoices(
          prePaymentDetail?.payment_invoices?.map((item) => ({
            ...item.user_tab_invoice,
            ...item,
            is_new: false,
          }))
        );
      }
    }
  }, [prePaymentDetail?.id, open]);

  return (
    <>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {isUpdate ? "EDIT " : "APPLY "}
            PAYMENT
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.invoiceForm}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Date of Payment
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                value={paymentDetail.payment_date}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "payment_date")
                }
                error={errors.payment_date}
                helperText={errors.payment_date}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Payment Amount
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.amount}
                onChange={(e) => handleUpdateDetail(e.target.value, "amount")}
                error={errors.amount}
                helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Payment Type
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.type}
                onChange={(e) => handleUpdateDetail(e.target.value, "type")}
                error={errors.type}
                helperText={errors.type}
                select
              >
                {/* "type" must be one of [E-Payment App: Zelle, E-Payment App: Venmo, E-Payment App: Cash App, Commission, Check, Card Payment] */}
                <MenuItem value="E-Payment App: Zelle">
                  E-Payment App: Zelle
                </MenuItem>
                <MenuItem value="E-Payment App: Venmo">
                  E-Payment App: Venmo
                </MenuItem>
                <MenuItem value="E-Payment App: Cash App">
                  E-Payment App: Cash App
                </MenuItem>
                <MenuItem value="Commission">Commission</MenuItem>
                <MenuItem value="Check">Check</MenuItem>
                <MenuItem value="Card Payment"> Card Payment </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.InputLabel}>
                Payment Description
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.description}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "description")
                }
                error={errors.description}
                helperText={errors.description}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ display: handleSuccessCall ? "none" : "" }}
            className={classes.unpaidInvoices}
          >
            {" "}
            <InputLabel className={classes.InputLabel2}>
              Select Unpaid Invoices to Apply Payment
            </InputLabel>
            <Box className={classes.invoiceTable}>
              <Box className={classes.tableRoot}>
                {" "}
                <DataGrid
                  rows={
                    unPaidInvoices?.data?.length ? unPaidInvoices?.data : []
                  }
                  columns={columns}
                  checkboxSelection
                  sx={{ border: 0 }}
                  hideFooter
                  onSelectionModelChange={handleSelectionChange}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: handleSuccessCall ? "none" : "" }}
            className={classes.attachmentSection}
          >
            <InputLabel className={classes.InputLabel2}>
              Add Attachment
            </InputLabel>
            <Box className={classes.attachments}>
              <Dropzone
                onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} className={classes.dropzoneSection}>
                    <div>
                      <input {...getInputProps()} />
                      <Typography>
                        Click to Upload or Drag & Drop Your File
                      </Typography>
                    </div>
                  </Box>
                )}
              </Dropzone>
            </Box>

            <Box sx={{ padding: "12px" }}>
              {filesPreview.length ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"flex-start"}
                  flexWrap="wrap"
                >
                  {filesPreview.map((item, index) => (
                    <Box sx={{ position: "relative", mb: "5px" }}>
                      {item.file.slice(17, 20) === "pdf" ? (
                        <a
                          href={item.file_path || item.url}
                          rel="noreferrer"
                          target={"_blank"}
                          style={{ display: "block" }}
                        >
                          <iframe
                            style={{ pointerEvents: "none" }}
                            width="120"
                            height="120"
                            src={item.file_path || item.file}
                          >
                            {" "}
                          </iframe>
                        </a>
                      ) : (
                        <a href={item.url} rel="noreferrer" target={"_blank"}>
                          {" "}
                          <img
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                            src={item.file_path || item.file}
                            alt={`expense document ${index}`}
                          />
                        </a>
                      )}

                      <HighlightOffRoundedIcon
                        onClick={() => handleDelFile(item)}
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              ) : null}
            </Box>
          </Box>

          <Box sx={{ mt: "24px" }}>
            <InputLabel className={classes.InputLabel}>
              Schedule Email Alerts to Agent
            </InputLabel>
            <Box className={classes.selectionBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={paymentDetail?.email_alert}
                    onChange={(e) =>
                      handleUpdateDetail(e.target.checked, "email_alert")
                    }
                  />
                }
                label="Send Payment Alert"
                color="inherit"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {" "}
          <Box className={classes.buttonSection}>
            {isUpdate ? (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                onClick={handleUpdatePayment}
                loadingPosition="start"
                loading={editPayment.isLoading}
                disabled={editPayment.isLoading}
              >
                Update Payment
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                onClick={handleApplyPayment}
                loadingPosition="start"
                loading={applyPayment.isLoading}
                disabled={applyPayment.isLoading}
              >
                Apply payment
              </LoadingButton>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this attachment?"
        handleConfirm={handleConfirm}
        loading={delAttachment.isLoading}
      />
    </>
  );
}
export default withStyles(styleSheet, { name: "ApplyPaymentStyle" })(
  ApplyPayment
);
